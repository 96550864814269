import React from "react";
import TabPanelProps from "../../interfaces/ITabPanel";

const TabPanel: React.FC<TabPanelProps> = ({ index, value, children }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: "calc(100% - 43px)", padding: "20px 0" }}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

export default TabPanel;
