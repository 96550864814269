import { IBusinessDashboard } from "../../../interfaces/IBusiness";
import styles from "./index.module.scss";
import ProgressBar from "../../ProgressBar";

export default ({
  keywordsAmount,
  groupsAmount,
  profilesAmount,
  postCommitAmount,
}: IBusinessDashboard) => (
  <div className={styles.wrapper}>
    <div className={styles.wrapper_left}>
      <div className={styles.wrapper_left_new}>
        <span>
          <div>Keywords</div>
          <div>{keywordsAmount}</div>
        </span>
      </div>
      <div className={styles.wrapper_left_edit}>
        <span>
          <div>Groups</div>
          <div>{groupsAmount}</div>
        </span>
      </div>
      <div className={styles.wrapper_left_ready}>
        <span>
          <div>Profiles</div>
          <div>{profilesAmount}</div>
        </span>
      </div>
      <div className={styles.wrapper_left_active}>
        <span>
          <div>Posts & comments</div>
          <div>{postCommitAmount}</div>
        </span>
      </div>
      <div className={styles.wrapper_left_controls}></div>
    </div>
    <div className={styles.wrapper_right}>
      <ProgressBar title='Loading #1 data' percentage={100}/>
      <ProgressBar title='Loading #2 data' percentage={20}/>
      <ProgressBar title='Loading #3 data' percentage={30}/>
    </div>
  </div>
);
