import styles from "./index.module.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

const Avatars: React.FC<any> = ({ users }) => (
  <div className={styles.wrapper}>
    <div className={styles.wrapper_statistic}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Last login</TableCell>
            <TableCell align="center">Last action</TableCell>
            <TableCell align="center">Groups</TableCell>
            <TableCell align="center">Posts</TableCell>
            <TableCell align="center">Comments</TableCell>
            <TableCell align="center">Used by</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((row: any) => (
            <TableRow key={row?.fullName }>
              <TableCell align="center">
                {row?.fullName || "Not found"}
              </TableCell>
              <TableCell align="center">
                {row?.lastLogin || "Not found"}
              </TableCell>
              <TableCell align="center">
                {row?.lastAction || "Not found"}
              </TableCell>
              <TableCell align="center">{row?.groups || "Not found"}</TableCell>
              <TableCell align="center">{row?.posts || "Not found"}</TableCell>
              <TableCell align="center">
                {row?.comments || "Not found"}
              </TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">{row?.status || "Not found"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  </div>
);

export default Avatars;
