import React, {ChangeEventHandler, FC, useState} from 'react';
import styles from './index.module.scss'
import HelpCenterApi from "../../../../api/helpCenter";

const HelpCenterQuestion: FC<{ id: number, answer: string, question: string, category_id: number }> = ({
                                                                                                         id,
                                                                                                         answer,
                                                                                                         question,
                                                                                                         category_id
                                                                                                       }) => {

  const [currentQuestion, setCurrentQuestion] = useState<string>(question);
  const [currentAnswer, setCurrentAnswer] = useState<string>(answer);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false)

  const onChangeQuestionHandler: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setCurrentQuestion(event.target.value);
  }

  const onChangeAnswerHandler: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setCurrentAnswer(event.target.value);
  }

  const editHandler = () => {
    setIsEdit(prev => !prev);
  };

  const onDeleteHandler = () => {
    HelpCenterApi.deleteHelpCenterQuestion(id).then(() => setIsDeleted(true));
  }

  const onSaveHandler = () => {
    HelpCenterApi.updateHelpCenterQuestion({
      id,
      category_id,
      answer: currentAnswer,
      question: currentQuestion
    }).then(_ => editHandler());
  };

  if (!answer || !question || !id || isDeleted) return null;

  return (
    <div className={styles.question__wrapper}>
      <div className={styles.question}>
        <div className={styles.question__section}>
          <span className={styles.question__section_header}>Question</span>
          <textarea onChange={onChangeQuestionHandler} disabled={!isEdit} className={styles.question__section_content}
                 defaultValue={question}/>
        </div>
        <div className={styles.question__section}>
          <span className={styles.question__section_header}>Answer</span>
          <textarea onChange={onChangeAnswerHandler} disabled={!isEdit} className={styles.question__section_content}
                 defaultValue={answer}/>
        </div>
      </div>
      <div className={styles.question__edit}>
        {isEdit && <>
          <div onClick={onSaveHandler} className={styles.question__edit__button}>Save</div>
          <div onClick={onDeleteHandler} className={styles.question__edit__button}>Delete</div>
        </>}
        <div onClick={editHandler} className={styles.question__edit__button}>{isEdit ? 'Cancel' : 'Edit'}</div>
      </div>
    </div>
  );
};

export default HelpCenterQuestion;