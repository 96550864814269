import React, {FC} from 'react';
import styles from "./index.module.scss";
import {IProgressBar} from "../../interfaces";

const ProgressBar: FC<IProgressBar> =
  ({
     percentage = 100,
     title,
     color = 'green'
   }) => {
    return (
      <div className={styles.progress_bar__container}>
        {title && <div className={styles.progress_bar__header}>{title}</div>}
        <div className={styles.progress_bar__body}>
          <div className={styles.progress_bar__percent}>{percentage}%</div>
          <div
            className={styles.progress_bar__line}
            style={{width: `${percentage}%`, backgroundColor: color}}
          />
        </div>
      </div>

    );
  };

export default ProgressBar;