import instance from "../api/instance";
import {setAuth} from "../redux/app/actions";
import store from "../redux/store";

export const checkToken = () => {
  const isToken = Boolean(JSON.parse(localStorage.getItem("SniApiToken") as string));

  if(isToken){
    store.dispatch(setAuth(true));
  }
};

export const saveToken = (userToken: string) => {

  localStorage.setItem("SniApiToken", JSON.stringify(userToken));
  // @ts-ignore
  instance.defaults.headers.Authorization = `Bearer ${userToken}`;

  store.dispatch(setAuth(true));
};