import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import styles from "./index.module.scss";

const rows = [
  {
    email: "asd@asdas.asd",
    name: "oleg",
    lastSean: new Date(),
    role: "owner",
  },
];

export default () => (
  <div className={`${styles.wrapper} tile`}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="center">Email</TableCell>
          <TableCell align="center">Name</TableCell>
          <TableCell align="center">LastSean</TableCell>
          <TableCell align="center">Set password</TableCell>
          <TableCell align="center">Reset password</TableCell>
          <TableCell align="center">Role</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.email}>
            <TableCell align="center">{row.email}</TableCell>
            <TableCell align="center">{row.name}</TableCell>
            <TableCell align="center">{`${row.lastSean.getDay()}/${row.lastSean.getMonth()}/${row.lastSean.getFullYear()}`}</TableCell>
            <TableCell align="center" />
            <TableCell align="center" />
            <TableCell align="center">{row.role}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
);
