import instance from "../instance";

export function getBusinesses() {
  return instance
    .get("/business")
    .then((response: any) => {
      if (response.status === 200) {
        if (response.data.success) {
          return response.data.data;
        }
      } else {
        return false;
      }
    })
    .catch(() => false);
}

export function getBusinessInfo(id: string | number) {
  return instance
    .get(`/business/${id}`)
    .then((response: any) => {
      if (response.status === 200) {
        if (response.data.success) {
          return response.data.data;
        }
      } else {
        return false;
      }
    })
    .catch(() => false);
}

export function removeBusinessKeyword(id: string | number, keyword: string, category: string) {
  return instance
    .put(`/business/removeKeyword`, {
      id,
      keyword,
      field: category
    })
    .then((response: any) => {
      if (response.status === 200) {
        if (response.data.success) {
          return response.data.data;
        }
      } else {
        return false;
      }
    })
    .catch(() => false);
}

export function addBusinessKeyword(id: string | number, keyword: string, category: string) {
  return instance
    .put(`/business/addKeyword`, {
      id,
      keyword,
      field: category
    })
    .then((response: any) => {
      if (response.status === 200) {
        if (response.data.success) {
          return response.data.data;
        }
      } else {
        return false;
      }
    })
    .catch(() => false);
}