import styles from "./index.module.scss";
import {
  Tab,
  Tabs,
} from "@material-ui/core";
import {DataGrid, GridColDef, GridValueGetterParams} from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import TabPanel from "../../components/TabPanel";
import IGPPS from "../../interfaces/IGPPS";

const GPPS: React.FC<IGPPS> = (
  {
    data,
    page,
    tab,
    rowsPerPage,
    handleChangeTab,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsCount
  }) => {

  return (
    <div className={styles.wrapper}>
      <span className={styles.wrapper_header}>GPPC</span>
      <div className={styles.wrapper_statistic}>
        {data?.length && (
          <>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
            >
              <Tab label="Group"/>
              <Tab label="Profiles"/>
              <Tab label="Posts"/>
              <Tab label="Comments"/>
            </Tabs>
            <TabPanel value={tab} index={0}>
              <Box sx={{height: '500px', width: '100%'}}>
                <DataGrid
                  getRowId={(row) => row.ID}
                  rows={data}
                  page={page}
                  columns={groupColumns}
                  pageSize={rowsPerPage}
                  rowsPerPageOptions={[10, 25, 30, 50, 100]}
                  rowCount={rowsCount}
                  paginationMode={'server'}
                  pagination={true}
                  onPageChange={handleChangePage}
                  onPageSizeChange={handleChangeRowsPerPage}
                  disableSelectionOnClick
                  experimentalFeatures={{newEditingApi: true}}
                />
              </Box>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Box sx={{height: '500px', width: '100%'}}>
                <DataGrid
                  columns={profileColumns}
                  getRowId={(row) => row.ID}
                  rows={data}
                  page={page}
                  pageSize={rowsPerPage}
                  rowsPerPageOptions={[10, 25, 30, 50, 100]}
                  rowCount={rowsCount}
                  paginationMode={'server'}
                  pagination={true}
                  onPageChange={handleChangePage}
                  onPageSizeChange={handleChangeRowsPerPage}
                  disableSelectionOnClick
                  experimentalFeatures={{newEditingApi: true}}
                />
              </Box>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <Box sx={{height: '500px', width: '100%'}}>
                <DataGrid
                  columns={postsColumns}
                  getRowId={(row) => row.ID}
                  rows={data}
                  page={page}
                  pageSize={rowsPerPage}
                  rowsPerPageOptions={[10, 25, 30, 50, 100]}
                  rowCount={rowsCount}
                  paginationMode={'server'}
                  pagination={true}
                  onPageChange={handleChangePage}
                  onPageSizeChange={handleChangeRowsPerPage}
                  disableSelectionOnClick

                  experimentalFeatures={{newEditingApi: true}}
                />
              </Box>
            </TabPanel>
            <TabPanel value={tab} index={3}>
              <Box sx={{height: '500px', width: '100%'}}>
                <DataGrid
                  columns={commentColumns}
                  getRowId={(row) => row.ID}
                  rows={data}
                  page={page}
                  pageSize={rowsPerPage}
                  rowsPerPageOptions={[10, 25, 30, 50, 100]}
                  rowCount={rowsCount}
                  paginationMode={'server'}
                  pagination={true}
                  onPageChange={handleChangePage}
                  onPageSizeChange={handleChangeRowsPerPage}
                  disableSelectionOnClick
                  experimentalFeatures={{newEditingApi: true}}
                />
              </Box>
            </TabPanel>
          </>
        )}
      </div>
    </div>
  )
};


const profileColumns: GridColDef[] = [
  {
    field: 'ProfileName',
    headerName: 'Profile name',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.ProfileName || 'Not found',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'Gender',
    headerName: 'Gender',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.Gender || 'Not found',
    headerAlign: 'center',
    align: 'center',
  },
];

const groupColumns: GridColDef[] = [
  {
    field: 'GroupName',
    headerName: 'Name',
    flex: 3,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.GroupName || 'Not found',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'LastTimeTaken',
    headerName: 'Status',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.LastTimeTaken || 'Not found',
    headerAlign: 'center',
    align: 'center',
  },
];

const postsColumns: GridColDef[] = [
  {
    field: 'ProfileName',
    headerName: 'Profile name',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.ProfileName || 'Not found',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'PostText',
    headerName: 'Post Text',
    flex: 3,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.PostText || 'Not found',
    headerAlign: 'center',
    align: 'center',
  },
];

const commentColumns: GridColDef[] = [
  {
    field: 'ProfileName',
    headerName: 'Profile name',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.ProfileName || 'Not found',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'CommentSemanthic',
    headerName: 'Comment Semanthic',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.CommentSemanthic || 'Not found',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'CommentText',
    headerName: 'Comment Text',
    flex: 4,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.CommentText || 'Not found',
    headerAlign: 'center',
    align: 'center',
  },
];


export default GPPS;
