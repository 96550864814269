import {useSelector} from "react-redux";
import SideBar from "./components/SideBar";
import { BrowserRouter } from "react-router-dom";
import RoutesComponent from "./routes";
import styles from "./index.module.scss";
import {IRootState} from "./interfaces/IRedux";
import {checkToken} from "./helpers/token";

checkToken();

const App = () => {

  const isAuth = useSelector((state: IRootState) => state.app.isAuth);

  return (
      <BrowserRouter>
        <div className={styles.app_layout}>
          {isAuth && <SideBar/>}
          <div className={styles.app_layout_child}>
            <RoutesComponent />
          </div>
        </div>
      </BrowserRouter>
  );
};

export default App;
