import instance from "../instance";

const HelpCenterApi = {
  getHelpCenter: async function () {
    return (await instance.get('/general/help')).data
  },
  updateHelpCenterQuestion: async function (data: {category_id: number, answer: string, question: string, id: number}) {
    return (await instance.put(`/general/help/question`, data)).data
  },
  createHelpCenterQuestion: async function (data: {category_id: number, answer: string, question: string}) {
    return (await instance.post(`/general/help/question`, data)).data
  },
  deleteHelpCenterQuestion: async function (id: number) {
    return (await instance.delete(`/general/help/question/${id}`,)).data
  },
}

export default HelpCenterApi