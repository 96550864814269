import React, {FC} from 'react';
import HelpCenterItem from "./HelpCenterItem";
import styles from './index.module.scss'

const HelpCenterList:FC<{list: any[]}> = ({list}) => {

  const categories = list.map(el => ({
    name: el.name,
    id: el.id,
    category: el.category
  }))

  return (
    <div className={styles.list}>
      {list.map(el => <HelpCenterItem categories={categories} key={el.id} id={el.id} name={el.name} category={el.category} questions={el.questions}/>)}
    </div>
  );
};

export default HelpCenterList;