import React, {FC, useMemo, useState} from 'react';
import styles from './index.module.scss'
import HelpCenterQuestion from "./HelpCenterQuestion";
import CreateHelpCenterQuestion from "./HelpCenterQuestion/CreateHelpCenterQuestion";

const HelpCenterItem: FC<{ id: number, name: string, category: string, questions: any[], categories: any[] }> =
  ({
     id,
     name,
     category,
     questions,
     categories
   }) => {

    const [showQuestions, setShowQuestions] = useState<boolean>(false);
    const [currentQuestions, setCurrentQuestions] = useState<any[]>(questions);
    const [newQuestion, setNewQuestion] = useState<any>(null);

    const handlerOpenQuestions = () => {
      setShowQuestions(prev => !prev)
    }

    const memorizedQuestions = useMemo(() => {
      if (newQuestion) {
        return [...questions, newQuestion]
      }
      return questions;
    }, [questions, newQuestion]);

    const openStyle = {
      backgroundColor: 'rgba(54, 192, 6, 0.94)'
    }

    const closeStyle = {
      backgroundColor: 'red'
    }

    const onCreateNewQuestion = (question: any) => {
      setNewQuestion(question);
    }


    return (
      <div style={{
        minHeight: showQuestions ? 'min-content' : '55px'
      }} className={styles.item}>
        <div className={styles.item__header}>
          <div className={styles.item__header__content}>
            <span>{category}</span>
            <span>{name}</span>
          </div>
          <div onClick={handlerOpenQuestions} style={showQuestions ? closeStyle : openStyle}
               className={styles.item__button}>{showQuestions ? 'Close' : 'Open'}</div>
        </div>
        {showQuestions &&
          (
            <div className={styles.item_body}>
              <div className={styles.item_content}>
                {memorizedQuestions.map(q => <HelpCenterQuestion key={q.id} id={q.id} answer={q.answer}
                                                                 question={q.question} category_id={id}/>)}
                <CreateHelpCenterQuestion onCreate={onCreateNewQuestion}
                                          category_id={id}/>
              </div>
            </div>)
        }
      </div>
    );
  };

export default HelpCenterItem;