import {
  ChangeEvent,
  useEffect,
  useState
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { compose } from "redux";
import GPPS from ".";
import { MonitoringApi } from "../../api/api";
import {GridCallbackDetails} from "@mui/x-data-grid";

const GPPSContainer: React.FC = () => {
  const navigate = useNavigate();
  const [list, setList] = useState<Array<any>>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [rowsCount, setRowsCount] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const startTab = Number(searchParams.get("tab")) || 0;

  const handleChangeTab = (event: ChangeEvent<{}>, newValue: number): void => {
    setPage(0);
    setRowsPerPage(10);
    navigate({
      search: `tab=${newValue}`
    });
  };

  const handleChangePage = (page: number, _: GridCallbackDetails<any>) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (pageSize: number, _: GridCallbackDetails) => {
    setRowsPerPage(pageSize);
    setPage(0);
  };

  useEffect(() => {
    fetchData(startTab, page, rowsPerPage).then(res => {
      setList(res.list);
      setRowsCount(res.count);
    });
  }, [page, rowsPerPage, startTab]);

  return (
    <GPPS
      data={list}
      tab={startTab}
      page={page}
      rowsCount={rowsCount}
      rowsPerPage={rowsPerPage}
      handleChangeTab={handleChangeTab}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
};

export default compose<any>(GPPSContainer);


const fetchData = async (tab: number, page: number, rowsPerPage: number): Promise<{list: any[], count: number}> => {
  const offset = page * rowsPerPage;
  switch (tab) {
    case 0: {
      const { groups, count } = await MonitoringApi.getGroups(offset, rowsPerPage);
      return {
        list: groups,
        count
      };
    }
    case 1: {
      const { users, count } = await MonitoringApi.getProfiles(offset, rowsPerPage);
      return {
        list: users,
        count
      };
    }
    case 2: {
      const { posts, count } = await MonitoringApi.getPosts(offset, rowsPerPage);
      return {
        list: posts,
        count
      };
    }
    case 3: {
      const { comments, count } = await MonitoringApi.getComments(offset, rowsPerPage);
      return {
        list: comments,
        count
      };
    }
    default:
      throw Error('ERROR FETCH');
  }
}
