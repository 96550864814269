import React, {useEffect, useState} from 'react';
import HelpCenterApi from "../../api/helpCenter";
import HelpCenterList from "../../components/HelpCenterList";
import styles from './index.module.scss'

const HelpCenter = () => {

  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    HelpCenterApi.getHelpCenter().then(result => setList(result.data));
  }, []);

  if (!list.length) return null;

  return (
    <div className={styles.helpCenter}>
      <HelpCenterList list={list}/>
    </div>
  );
};

export default HelpCenter;