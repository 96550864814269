import { useEffect, useState } from "react";
import { compose } from "redux";
import Avatars from ".";
import { MonitoringApi } from "../../api/api";

const AvatarsContainer: React.FC = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    MonitoringApi.getUsers().then((usersResponse) => {
      setUsers(usersResponse.users);
    });
  }, []);

  if (!users?.length) return null;

  return <Avatars users={users} />;
};

export default compose<any>(AvatarsContainer);
