import instance from "./instance";

export const AuthApi = {
  login(
    email: string,
    password: string
  ): Promise<string | boolean | undefined> {
    return instance
      .get(`/login?mail=${email}&password_=${password}`)
      .then((response: any) => {
        if (response.status === 200) {
          const token: string | undefined = response.data.data.token;
          if (token) return token;
          return false;
        }
      })
      .catch(() => false);
  }
};


function responseHandlerMonitoringKeyword(response: any, field: string) {
  if (response.data.success) {
    return {
      [field]: response.data.data[field],
      count: response.data.properties.count
    };
  }
  return {
    [field]: [],
    count: 0
  };
}

export const MonitoringApi = {
  getDashboard() {
    return instance
      .get("/monitoring/dashboard")
      .then((response: any) => {
        if (response.status === 200) {
          if (response.data.success) {
            return response.data.data;
          }
        } else {
          return false;
        }
      })
      .catch(() => false);
  },
  getUsers() {
    return instance
      .get("/monitoring/users")
      .then((response: any) => {
        if (response.status === 200) {
          if (response.data.success) {
            return response.data.data;
          }
        } else {
          return false;
        }
      })
      .catch(() => false);
  },
  getGroups(offset: number = 0, limit: number = 10) {
    return instance
      .get(`/monitoring/keywordGroups?offset=${offset}&limit=${limit}`)
      .then((res: any) =>
        responseHandlerMonitoringKeyword(res, "groups") as {
          groups: any[]
          count: number
        })
      .catch(() => {
        throw Error("getGroups error");
      });
  },
  getProfiles(offset: number = 0, limit: number = 10) {
    return instance
      .get(`/monitoring/keywordUsers?offset=${offset}&limit=${limit}`)
      .then((res: any) =>
        responseHandlerMonitoringKeyword(res, "users") as {
          users: any[],
          count: number
        })
      .catch(() => {
        throw Error("getProfiles error");
      });
  },
  getPosts(offset: number = 0, limit: number = 10) {
    return instance
      .get(`/monitoring/keywordPosts?offset=${offset}&limit=${limit}`)
      .then((res: any) =>
        responseHandlerMonitoringKeyword(res, "posts") as {
          posts: any[],
          count: number
        })
      .catch(() => {
        throw Error("getPosts error");
      });
  },
  getComments(offset: number = 0, limit: number = 10) {
    return instance
      .get(`/monitoring/keywordComments?offset=${offset}&limit=${limit}`)
      .then((res: any) =>
        responseHandlerMonitoringKeyword(res, "comments") as {
          comments: any[],
          count: number
        })
      .catch(() => {
        throw Error("getComments error");
      });
  }
};

