import styles from "./index.module.scss";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

const users = [
  {
    name: "Company name",
    payment: "Free",
    value: "$1,654,654",
    revenue: "$1,654,654",
  },
];
const data = [
  {
    y: 1,
    x: 2,
  },
  {
    y: 1.7,
    x: 6,
  },
  {
    y: 2.7,
    x: 7,
  },
  {
    y: 3.7,
    x: 6.5,
  },
  {
    y: 4.7,
    x: 7.5,
  },
  {
    y: 5.7,
    x: 5.5,
  },
  {
    y: 7.6,
    x: 8,
  },
];

const Revenue: React.FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.wrapper_chart}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
          <XAxis dataKey="y" type="number" />
          <YAxis dataKey="x" type="number" />
          <Tooltip />
          <CartesianGrid stroke="#f5f5f5" />
          <Line dataKey="x" stroke="#ff7300" yAxisId={0} />
        </LineChart>
      </ResponsiveContainer>
    </div>
    <div className={`${styles.wrapper_statistic} tile`}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Comp. name</TableCell>
            <TableCell align="center">Payment</TableCell>
            <TableCell align="center">L. Invoice</TableCell>
            <TableCell align="center">Customer value</TableCell>
            <TableCell align="center">Revenue</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((row) => (
            <TableRow key={row.name}>
              <TableCell align="center">{row.name}</TableCell>
              <TableCell align="center">{row.payment}</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">{row.value}</TableCell>
              <TableCell align="center">{row.revenue}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  </div>
);

export default Revenue;
