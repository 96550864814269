import {ITextInputContainer} from "../../interfaces/ITextInput";
import styles from "./index.module.scss";
import {compose} from "redux";
import WithPreloader from "../../hoc/WithPreloader";

const TextInput: React.FC<ITextInputContainer> =
  ({
     type,
     disabled,
     placeholder,
     onChange,
     className,
     isChecked,
   }) => {
    return (
      <input
        type={type}
        placeholder={placeholder}
        className={`${styles.textInput} ${className}`}
        disabled={disabled}
        onChange={onChange}
        style={isChecked ? {borderColor: "red"} : undefined}
      />
    )
  };

export default compose<any>(WithPreloader)(TextInput);
