import React from "react";
import { IBusinessInfo } from "../../../interfaces/IBusiness";
import styles from "./index.module.scss";

export default ({
  businessInfo,
}: {
  businessInfo: IBusinessInfo | undefined;
}) => (
  <div className={styles.wrapper}>
    <div className={`${styles.wrapper_item} tile`}>
      <div className={styles.wrapper_item__title}>Plan details</div>
      <div className={styles.wrapper_item_content}>
        <div className={styles.wrapper_item_content__title}>Package name</div>
        <div className={styles.wrapper_item_content__info}>
          Plan renew on 12/12/21
        </div>
      </div>
      <div className={styles.wrapper_item_action}>
        <span>Change plan</span>
        <span>Switch to annual plan</span>
        <span>Plan history</span>
      </div>
    </div>
    <div className={`${styles.wrapper_item} tile`}>
      <div className={styles.wrapper_item__title}>Business details</div>
      <div className={styles.wrapper_item_content}>
        <div className={styles.wrapper_item_content__title}>Business name</div>
        <div className={styles.wrapper_item_content__info}>
          Reg number: {businessInfo?.regNumber}
        </div>
      </div>
      <div className={styles.wrapper_item_action}>
        <span>Edit business</span>
      </div>
    </div>
    <div className={`${styles.wrapper_item} tile`}>
      <div className={styles.wrapper_item__title}>Payment info</div>
      <div className={styles.wrapper_item_content}>
        <div className={styles.wrapper_item_content__title}>
          MasterCard ending in 1234
        </div>
        {/* <div className={styles.wrapper_item_content__info}>Plan renew on 12/12/21</div> */}
      </div>
      <div className={styles.wrapper_item_action}>
        <span>Change creditCard</span>
      </div>
    </div>
    <div className={`${styles.wrapper_item} tile`}>
      <div className={styles.wrapper_item__title}>Billing</div>
      <div className={styles.wrapper_item_content}>
        <div className={styles.wrapper_item_content__title}>Name Last_name</div>
        <div className={styles.wrapper_item_content__info}>
          example@example.com
        </div>
      </div>
      <div className={styles.wrapper_item_action}>
        <span>Change billing info</span>
      </div>
    </div>
    <div className={`${styles.wrapper_item} tile`}>
      <div className={styles.wrapper_item__title}>Invoices</div>
      <div className={styles.wrapper_item_content}>
        <div className={styles.wrapper_item_content__title}>Latest invoice</div>
        <div className={styles.wrapper_item_content__info}>
          Invoiced on: 12/12/21
        </div>
      </div>
      <div className={styles.wrapper_item_action}>
        <span>View latest</span>
        <span>Invoice history</span>
      </div>
    </div>
  </div>
);
