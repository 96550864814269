import { useState } from "react";
import Preloader from "../../components/Preloader";
import { IWithPreloader } from "../../interfaces/IHoc";

const WithPreloader = (Component: React.FC<IWithPreloader>) => {
  const WithPreloaderCom: React.FC = (hocProps) => {
    const [isLoading, setLoading] = useState(false);
    return (
      <>
        {isLoading && <Preloader />}
        <Component {...hocProps} setLoading={setLoading} />
      </>
    );
  };

  return WithPreloaderCom;
};

export default WithPreloader;
