import {ChangeEvent, useCallback, useState} from "react";
import WithPreloader from "../../hoc/WithPreloader";
import { compose } from "redux";
import { AuthApi } from "../../api/api";
import { IWithPreloader } from "../../interfaces/IHoc";
import { IHandleLogin } from "../../interfaces/ILogin";
import styles from "./index.module.scss";
import TextInputContainer from "../../components/TextInput";
import {saveToken} from "../../helpers/token";
import {useNavigate} from "react-router-dom";

const LoginContainer: React.FC<IWithPreloader> = ({
  setLoading,
}) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();

  const handleLogin: IHandleLogin = async (email, password) => {
    setLoading(true);
    const token = await AuthApi.login(email, password);
    setLoading(false);
    if (typeof token === "string") {
      saveToken(token);
      navigate('/dashboard');
    }
  };

  const handlerOnChangeEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    [setEmail]
  );

  const handlerOnChangePassword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    },
    [setPassword]
  );

  const handlerOnClickLogin = useCallback(() => {
    if (email && password) {
      handleLogin(email, password);
    } else {
      // setChecked(true);
    }
  }, [handleLogin,]);

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.wrapper_form} tile`}>
        <div className={styles.wrapper_form_content}>
          {/* <img className={styles.form_content__img} src={logo} alt={"logo"} /> */}
          <div className={styles.wrapper_form_content__text}>Login</div>
          <TextInputContainer
            type="text"
            placeholder="Email"
            className={styles.textInput}
            onChange={handlerOnChangeEmail}
          />
          <TextInputContainer
            type="password"
            placeholder="Password"
            className={styles.textInput}
            onChange={handlerOnChangePassword}
          />
          <button onClick={handlerOnClickLogin} className={styles.btn}>
            login
          </button>
        </div>
        {/* <img className={styles.background} src={login} alt={"login"} /> */}
      </div>
    </div>
  );
};

export default compose<any>(WithPreloader)(LoginContainer);
