import {
  Table,
  TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import styles from "./index.module.scss";

const BusinessList: React.FC<any> = ({ businesses, handleAction }) => (
  <div className={styles.wrapper}>
    <TableContainer>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Comp. name</TableCell>
            <TableCell align="left">Customer value</TableCell>
            <TableCell align="left">Payment</TableCell>
            <TableCell align="left">L. Invoice</TableCell>
            <TableCell align="left">Last generated</TableCell>
            <TableCell align="left">Join date</TableCell>
            <TableCell align="left">Keywords/package</TableCell>
            <TableCell align="left">S. keywords</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody >
          {businesses.map((business: any) => (
            <TableRow key={business.id}>
              <TableCell align="left">
                {business?.legalName || "Not found"}
              </TableCell>
              <TableCell align="left">
                {business?.regNumber || "Not found"}
              </TableCell>
              <TableCell align="left">
                {business?.payment || "Not found"}
              </TableCell>
              <TableCell align="center">{"O" || "Not found"}</TableCell>
              <TableCell align="left">
                {business?.updatedAt
                  ? `${new Date(business.updatedAt).getDay()}/${new Date(
                    business.updatedAt
                  ).getMonth()}/${new Date(business.updatedAt).getFullYear()}`
                  : "Not found"}
              </TableCell>
              <TableCell align="left">
                {business?.createdAt
                  ? `${new Date(business.createdAt).getDay()}/${new Date(
                    business.createdAt
                  ).getMonth()}/${new Date(business.createdAt).getFullYear()}`
                  : "Not found"}
              </TableCell>
              <TableCell align="center">
                {business?.package || "Not found"}
              </TableCell>
              <TableCell align="left">
                {business?.keywordsAmount || "Not found"}
              </TableCell>
              <TableCell align="left">
                {business?.status || "Not found"}
              </TableCell>
              <TableCell align="left">
                <button
                  onClick={() => {
                    handleAction(business.id);
                  }}
                >
                  .
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
);

export default BusinessList;
