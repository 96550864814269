import React, {FC, MouseEventHandler} from 'react';
import styles from './index.module.scss';
import {Button} from "@mui/material";

interface IButton {
  onClick: MouseEventHandler<HTMLButtonElement>,
  text: string,
  variant?: 'primary' | 'secondary',
  className?: string
}

const CustomButton: FC<IButton> =
  ({
     onClick,
     text,
     variant = 'primary',
     className = ''
   }) => {

    let customClassName = `${className} `

    switch (variant) {
      case "primary": {
        customClassName+=styles.primary
        break;
      }
      case "secondary": {
        customClassName+=styles.secondary
        break;
      }
      default: {
        break;
      }
    }


    return (
      <button onClick={onClick} className={customClassName}>
        {text}
      </button>
    );
  };

export default CustomButton;