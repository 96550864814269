import { IAction, IAppState } from "../../interfaces/IRedux";

export const SET_IS_NEW_NOTIFICATION: string = "SET_IS_NEW_NOTIFICATION";
export const SET_IS_AUTH: string = 'SET_IS_AUTH';

const initialState: IAppState = {
  status: true,
  isAuth: false
};

const appReducer = (state: IAppState = initialState, action: IAction) => {
  switch (action.type) {
    case SET_IS_NEW_NOTIFICATION:
      return {
        ...state,
        isNewNotification: action.payload,
      };
    case SET_IS_AUTH: {
     return {
       ...state,
       isAuth: action.payload
     }
    }
    default:
      return {
        ...state,
      };
  }
};

export default appReducer;
