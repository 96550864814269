import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { compose } from "redux";
import BusinessList from "./list";
import { ChangeEvent, useEffect, useState } from "react";
import { IBusinessInfo } from "../../interfaces/IBusiness";
import {getBusinesses, getBusinessInfo} from "../../api/business";
import styles from "./index.module.scss";
import {Tab, Tabs} from "@material-ui/core";
import TabPanel from "../../components/TabPanel";
import BusinessDashboard from "../../components/Business/Dashboard";
import BusinessKeywords from "../../components/Business/Keywords";
import BusinessUsers from "../../components/Business/Users";
import BusinessInfo from "../../components/Business/Info";

const Business: React.FC = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState<number>(Number(searchParams.get("tab")) || 0);
  const [keywordsTab, setKeywordsTab] = useState<number>(0);
  const [businesses, setBusinesses] = useState<Array<any>>([]);
  const [businessInfo, setBusinessInfo] = useState<IBusinessInfo>();

  const handleChangeTab = (event: ChangeEvent<{}>, newValue: number): void => {
    setTab(newValue);
    navigate({
      search: `tab=${newValue}`
    });
  };

  const handleChangeKeywordsTab = (
    event: ChangeEvent<{}>,
    newValue: number
  ): void => {
    setKeywordsTab(newValue);
  };

  const handleAction = (value: string): void => {
    navigate({
      pathname: `/business/${value}`
    });
  };

  const getData = async (id: string | undefined, tab: number) => {
    if (!id) {
      const businessesResponse = await getBusinesses();
      if (!businessesResponse) return alert("error");
      setBusinesses(businessesResponse.businesses);
    } else {
      const businessInfoResponse = await getBusinessInfo(id);
      if (!businessInfoResponse) return alert("error");
      setBusinessInfo(businessInfoResponse.business);
    }
  };

  useEffect(() => {
    getData(id, tab);
  }, [id]);

  useEffect(() => {
    setTab(Number(searchParams.get("tab")));
  }, [searchParams.get("tab")]);

  if (id)
    return (<div className={styles.wrapper}>
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
      >
        <Tab label="Dashboard" />
        <Tab label="Keywords" />
        <Tab label="Reports" />
        <Tab label="System users" />
        <Tab label="Company info & settings" />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <BusinessDashboard
          keywordsAmount={businessInfo?.keywordsAmount || 0}
          groupsAmount={businessInfo?.groupsAmount || 0}
          profilesAmount={businessInfo?.profilesAmount || 0}
          postCommitAmount={businessInfo?.postCommitAmount || 0}
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <BusinessKeywords
          keywordsTab={keywordsTab}
          businessInfo={businessInfo}
          handleChangeKeywordsTab={handleChangeKeywordsTab}
        />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <></>
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <BusinessUsers />
      </TabPanel>
      <TabPanel value={tab} index={4}>
        <BusinessInfo businessInfo={businessInfo} />
      </TabPanel>
    </div>
    );

  return <BusinessList businesses={businesses} handleAction={handleAction} />;
};

export default compose<any>(Business);
