import {SET_IS_AUTH, SET_IS_NEW_NOTIFICATION} from "./reducer";

const setAppStatus = (status: boolean) => ({
  type: SET_IS_NEW_NOTIFICATION,
  payload: status,
});

export const setAuth = (isAuth: boolean) => ({
  type: SET_IS_AUTH,
  payload: isAuth
})

// export const getUserInfoAC = () => async (dispatch: Dispatch) => {
//   const userInfo: IUserInfo = await AuthApi.getUserInfo();
//   dispatch(setUserData(userInfo));
//   dispatch(setAppStatus(userInfo.order_ready_status));
//   dispatch(setUserStatus(userInfo.status));
// };
