import IDashboard from "../../interfaces/IDashboard";
import styles from "./index.module.scss";

const Dashboard: React.FC<IDashboard> = ({
                                           keywordsCommentsCount,
                                           keywordsCount,
                                           keywordsGroupsCount,
                                           keywordsPostsCount,
                                           keywordsUsersCount,
                                           usersCount,
                                         }) => (
  <div className={styles.wrapper}>
    <div className={styles.wrapper_data}>
      <div>
        <div className={styles.wrapper_data_title}>Customers:</div>
        <div className={styles.wrapper_data_tile + " tile"}>
          <img
            className={styles.wrapper_data_tile_img}
            src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80"
          />
          <div className={styles.wrapper_data_tile_info}>
            <div>Package name</div>
            <div>59</div>
          </div>
        </div>
      </div>
      <div className={styles.wrapper_data_tile + " tile"}>
        <img
          className={styles.wrapper_data_tile_img}
          src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80"
        />
        <div className={styles.wrapper_data_tile_info}>
          <div>Package name</div>
          <div>59</div>
        </div>

      </div>
      <div className={styles.wrapper_data_tile + " tile"}>
        <img
          className={styles.wrapper_data_tile_img}
          // src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80"
        />
        <div className={styles.wrapper_data_tile_info}>
          <div>Package name</div>
          <div>59</div>
        </div>

      </div>
      <div>
        <div>
          <div className={styles.wrapper_data_title}>Income:</div>
          <div className={styles.wrapper_data_tile + " tile"}>
            <img
              className={styles.wrapper_data_tile_img}
              // src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80"
            />
            <div className={styles.wrapper_data_tile_info}>
              <div>Revenues</div>
              <div>$5,965</div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div className={styles.wrapper_data}>
      <div>
        <div className={styles.wrapper_data_title}>Data:</div>
        <div className={styles.wrapper_data_tile + " tile"}>
          <img
            className={styles.wrapper_data_tile_img}
            // src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80"
          />
          <div className={styles.wrapper_data_tile_info}>
            <div>Active keywords</div>
            <div>846</div>
          </div>
        </div>
      </div>
      <div className={styles.wrapper_data_tile + " tile"}>
        <img
          className={styles.wrapper_data_tile_img}
          // src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80"
        />
        <div className={styles.wrapper_data_tile_info}>
          <div>Suggested keywords</div>
          <div>{keywordsCount}</div>
        </div>
      </div>
      <div className={styles.wrapper_data_tile + " tile"}>
        <img
          className={styles.wrapper_data_tile_img}
          // src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80"
        />
        <div className={styles.wrapper_data_tile_info}>
          <div>Groups in system</div>
          <div>{keywordsGroupsCount}</div>
        </div>
      </div>
      <div>
        <div className={styles.wrapper_data_title}>Avatars:</div>
        <div className={styles.wrapper_data_tile + " tile"}>
          <img
            className={styles.wrapper_data_tile_img}
            // src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80"
          />
          <div className={styles.wrapper_data_tile_info}>
            <div>Total</div>
            <div>{usersCount}</div>
          </div>

        </div>
      </div>
      <div className={styles.wrapper_data_tile + " tile"}>
        <img
          className={styles.wrapper_data_tile_img}
          // src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80"
        />
        <div className={styles.wrapper_data_tile_info}>
          <div>Profiles</div>
          <div>{keywordsUsersCount}</div>
        </div>
      </div>
      <div className={styles.wrapper_data_tile + " tile"}>
        <img
          className={styles.wrapper_data_tile_img}
          // src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80"
        />
        <div className={styles.wrapper_data_tile_info}>
          <div>Posts</div>
          <div>{keywordsPostsCount}</div>
        </div>
      </div>
      <div className={styles.wrapper_data_tile + " tile"}>
        <img
          className={styles.wrapper_data_tile_img}
          // src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80"
        />
        <div className={styles.wrapper_data_tile_info}>
          <div>Comments</div>
          <div>{keywordsCommentsCount}</div>
        </div>
      </div>
      <div>
        <div className={styles.wrapper_data_tile + " tile"}>
          <img
            className={styles.wrapper_data_tile_img}
            // src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80"
          />
          <div className={styles.wrapper_data_tile_info}>
            <div>Active</div>
            <div>{usersCount}</div>
          </div>

        </div>
      </div>
    </div>
  </div>
);

export default Dashboard;
