import styles from "./index.module.scss";
import loader from "../../assets/img/loader.svg";

const Preloader: React.FC = () => (
  <div className={styles.wrapper}>
    <img src={loader} alt="loader" />
  </div>
);

export default Preloader;
