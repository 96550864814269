import axios from "axios";
import logout from "../helpers/logout";

const token = JSON.parse(localStorage.getItem("SniApiToken") as string);

export const dataAxios: {
  baseURL: string;
  headers: undefined | { [key: string]: any };
} = {
  baseURL:
    process.env.REACT_APP_NODE_ENV !== "development"
      ? "https://midapi.sni.ai/adminNew"
      : "http://localhost:3333/api/admin",
  headers: token ? { Authorization: `Bearer ${token}` } : undefined,
};

const instance = axios.create(dataAxios);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("[error]", error);
    const status = error.request.status;
    if (status === 401) {
      const token = localStorage.getItem("SniApiToken");
      if (token) {
        logout();
      }
    }
    if (error.response?.data?.errors) {
      Object.values(error.response?.data?.errors)?.forEach((string) =>
        alert(string as string)
      );
    }
    return Promise.reject(error);
  }
);

export default instance;
