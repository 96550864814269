import React, {FC, MouseEventHandler} from 'react';
import {Modal} from "@mui/material";
import styles from './index.module.scss'
import CustomButton from "../common/Button";
import {IKeywordModal} from "../../interfaces/components";

const KeywordModal: FC<IKeywordModal> =
  ({
     onConfirm,
     isOpen,
     onClose,
     header,
     body
   }) => {

    const onCloseHandler: MouseEventHandler = () => {
      onClose();
    }

    const onConfirmHandler: MouseEventHandler = () => {
      onConfirm();
    }

    return (
      <Modal onClose={onClose} open={isOpen}>
        <div className={styles.modal}>
          <div className={styles.modal_header}>
            {header}
          </div>
          <div className={styles.modal_body}>
            {body}
          </div>
          <div className={styles.modal_footer}>
            <CustomButton onClick={onConfirmHandler} text='Yes'/>
            <CustomButton className={styles.button_cancel} variant='secondary' onClick={onCloseHandler} text='Cancel'/>
          </div>
        </div>
      </Modal>
    );
  };

export default KeywordModal;