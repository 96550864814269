import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import DashboardContainer from "./pages/Dashboard/container";
import BusinessContainer from "./pages/Business";
import RevenueContainer from "./pages/Revenue/container";
import AvatarsContainer from "./pages/Avatars/container";
import GPPSContainer from "./pages/GPPS/container";
import NotFound from "./pages/NotFound";
import {useSelector} from "react-redux";
import {IRootState} from "./interfaces/IRedux";
import HelpCenter from "./pages/HelpCenter";

const RoutesComponent: React.FC = () => {

  const isAuth = useSelector((state: IRootState) => state.app.isAuth);

  if (!isAuth) {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" replace />}/>
      </Routes>
    );
  }

  return (
      <Routes>
        <Route index element={<Navigate to="/dashboard" replace />} />
        <Route path="/dashboard" element={<DashboardContainer />} />
        <Route path="/business" element={<BusinessContainer />} />
        <Route path="/business/:id" element={<BusinessContainer />} />
        <Route path="/revenue" element={<RevenueContainer />} />
        <Route path="/avatars" element={<AvatarsContainer />} />
        <Route path="/GPPS" element={<GPPSContainer />} />
        <Route path="/helpCenter" element={<HelpCenter/>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
  );
};

export default RoutesComponent;
