import React, {ChangeEventHandler, FC, useState} from 'react';
import styles from './index.module.scss'
import HelpCenterApi from "../../../../api/helpCenter";

const HelpCenterQuestion: FC<{
  category_id: number,
  onCreate: (arg: any) => void,
}> =
  ({
     category_id,
     onCreate,
   }) => {

    const [currentQuestion, setCurrentQuestion] = useState<string>('');
    const [currentAnswer, setCurrentAnswer] = useState<string>('');
    const [isAdd, setIsAdd] = useState<boolean>(false);


    const onChangeQuestionHandler: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
      setCurrentQuestion(event.target.value);
    }

    const onChangeAnswerHandler: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
      setCurrentAnswer(event.target.value);
    }

    const onSaveHandler = () => {
      HelpCenterApi.createHelpCenterQuestion({
        category_id,
        answer: currentAnswer,
        question: currentQuestion
      }).then(res => {
        onCreate(res?.data);
        onAddHandler()
      })
    };

    const onAddHandler = () => {
      setIsAdd(prev => !prev);
    }

    if (!isAdd) return <div onClick={onAddHandler} className={styles.question__edit__button}>Create new one</div>;

    return (
      <div className={styles.question__wrapper}>
        <div className={styles.question}>
          <div className={styles.question__section}>
            <span className={styles.question__section_header}>Question</span>
            <textarea onChange={onChangeQuestionHandler} className={styles.question__section_content} defaultValue={''}/>
          </div>
          <div className={styles.question__section}>
            <span className={styles.question__section_header}>Answer</span>
            <textarea onChange={onChangeAnswerHandler} className={styles.question__section_content} defaultValue={''}/>
          </div>
        </div>
        <div className={styles.question__edit}>
          <div onClick={onSaveHandler} className={styles.question__edit__button}>Save</div>
          <div onClick={onAddHandler} className={styles.question__edit__button}>Cancel</div>
        </div>
      </div>
    );
  };

export default HelpCenterQuestion;