import {Tab, Tabs} from "@material-ui/core";
import {IBusinessKeywords} from "../../../interfaces/IBusiness";
import KeywordInput from "../../KeywordInput";
import TabPanel from "../../TabPanel";
import styles from "./index.module.scss";
import {ChangeEvent, ChangeEventHandler, FC, ReactNode, useState} from "react";
import KeywordModal from "../../Modals/KeywordModal";
import {addBusinessKeyword, removeBusinessKeyword} from "../../../api/business";

const BusinessKeywords = ({
                            businessInfo,
                            keywordsTab,
                            handleChangeKeywordsTab,
                          }: IBusinessKeywords) => {

  return (
    <div className={styles.wrapper}>
      <Tabs
        className={styles.wrapper_tabs}
        value={keywordsTab}
        onChange={handleChangeKeywordsTab}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
      >
        <Tab label="Business category"/>
        <Tab label="Business commercial names"/>
        <Tab label="Products & services that interests you"/>
        <Tab label="Business commercial names"/>
        <Tab label="General keywords"/>
      </Tabs>
      <TabPanel value={keywordsTab} index={0}>
        <div className={styles.wrapper_keywords}>
          {businessInfo?.category?.map(keyword => (
            <KeywordInput
              category={'category'}
              businessId={businessInfo?.id}
              key={keyword}
              keyword={keyword}
            />
          ))}
          <AddKeyword businessId={businessInfo?.id} category={'category'}/>
        </div>
      </TabPanel>
      <TabPanel value={keywordsTab} index={1}>
        <div className={styles.wrapper_keywords}>
          {/* {businessInfo?.category?.map((keyword, index) => (
          <KeywordInput
            key={index}
            value={keyword}
            onChange={handleChangeKeyword('category', index)}
          />
        ))} */}
        </div>
      </TabPanel>
      <TabPanel value={keywordsTab} index={2}>
        <div className={styles.wrapper_keywords}>
          {businessInfo?.interesting?.facebookGroups?.map(keyword => (
            <KeywordInput
              category={'interesting'}
              businessId={businessInfo?.id}
              key={keyword}
              keyword={keyword}
            />
          ))}
          <AddKeyword businessId={businessInfo?.id} category={'interesting'}/>

        </div>
      </TabPanel>
      <TabPanel value={keywordsTab} index={3}>
        <div className={styles.wrapper_keywords}>
          {/* {businessInfo?.category?.map((keyword, index) => (
          <KeywordInput
            key={index}
            value={keyword}
            onChange={handleChangeKeyword('category', index)}
          />
        ))} */}
        </div>
      </TabPanel>
      <TabPanel value={keywordsTab} index={4}>
        <div className={styles.wrapper_keywords}>
          {businessInfo?.general?.keywords?.map(keyword => (
            <KeywordInput
              category={'general'}
              businessId={businessInfo?.id}
              key={keyword}
              keyword={keyword}
            />
          ))}
          <AddKeyword businessId={businessInfo?.id} category={'general'}/>
        </div>
      </TabPanel>
    </div>
  )
};


const AddKeyword: FC<{ category: string, businessId: number | undefined }> = ({category, businessId}) => {
  const [newKeywords, setNewKeywords] = useState<ReactNode[]>([]);
  const [value, setValue] = useState<string>('')
  const [isOpen, setOpen] = useState<boolean>(false);

  if (!businessId) return null;

  const onCloseHandler = () => {
    setOpen(false);
  }

  const onConfirm = () => {
    if (value.length >= 4) {
      addBusinessKeyword(businessId, value, category).then(_ => {
        onCloseHandler();
        setNewKeywords(prev => [...prev, <KeywordInput category={category} businessId={businessId} keyword={value}/>])
      })
    } else {
      alert('Incorrect new keyword, minimum length 4 symbols')
    }
  }

  const onOpenHandler = () => {
    setOpen(true);
  }

  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value.length >= 4) {
      setValue(e.target.value);
    }
  }

  return (
    <>
      {newKeywords}
      <div className={styles.wrapper_new}>
        <span onClick={onOpenHandler}>Add Keyword</span>
        <KeywordModal
          onConfirm={onConfirm}
          onClose={onCloseHandler}
          isOpen={isOpen}
          header={`Add new keyword to ${category} list`}
          body={<input placeholder='New keyword' className={styles.input} onChange={onChangeHandler}/>}
        />
      </div>
    </>
  )

}

export default BusinessKeywords;