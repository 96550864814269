import styles from "./index.module.scss";
import IKeywordItem from "../../interfaces/IKeywordInput";
import {useState} from "react";
import KeywordModal from "../Modals/KeywordModal";
import {removeBusinessKeyword} from "../../api/business";

export default ({keyword, businessId, category}: IKeywordItem) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isRemoved, setIsRemoved] = useState<boolean>(false);

  const onCloseHandler = () => {
    setOpen(false);
  }

  const onConfirm = () => {
    removeBusinessKeyword(businessId, keyword, category).then(_ => {
      onCloseHandler();
      setIsRemoved(true);
    })
  }

  const onOpenHandler = () => {
    setOpen(true);
  }

  if(isRemoved) return null;

  return (
    <div className={styles.wrapper}>
      <span onClick={onOpenHandler}>{keyword}</span>
      <KeywordModal
        onConfirm={onConfirm}
        onClose={onCloseHandler}
        isOpen={isOpen}
        header={`Changes for ${keyword}`}
        body={`Remove (${keyword}) keyword?`}
      />
    </div>
  );
};
