import {NavLink} from "react-router-dom";
import DashboardIcon from "../../assets/img/dashboard.svg";
import PeopleIcon from "../../assets/img/people.svg";
import BookIcon from "../../assets/img/book.svg";
import PeopleTeamIcon from "../../assets/img/peopleTeam.svg";
import Logout from "../../assets/img/logout.svg";
import PhotoIcon from "../../assets/img/photo.svg";
import styles from "./index.module.scss";
import Admin from "../../assets/img/admin.svg"
import logout from "../../helpers/logout";
import {useCallback} from "react";


const links = [
  {
    icon: DashboardIcon,
    to: '/dashboard'
  },
  {
    icon: PeopleTeamIcon,
    to: '/business'
  },
  {
    icon: BookIcon,
    to: '/revenue'
  },
  {
    icon: PeopleIcon,
    to: '/avatars'
  },
  {
    icon: PhotoIcon,
    to: '/GPPS'
  },
  {
    icon: PhotoIcon,
    to: '/helpCenter'
  }
]


const SideBarLayout: React.FC = () => {
  const classNameGetter = useCallback(
    (data: { isActive: boolean }): string => {
      return `${styles.link_item} ${data.isActive ? styles.link_item_active : ""}`
    },
    []
  );

  return (
    <div className={styles.wrapper}>
      <span className={styles.wrapper_header}>SNI</span>
      <div className={styles.link_list}>
        {links.map(link => (
          <NavLink to={link.to} className={classNameGetter}>
            <div className={styles.link_item_img} style={{
              backgroundImage: `url(${link.icon})`
            }}/>
          </NavLink>
        ))}

        <a href={'https://admin.sni.ai/sni-admin-manual/dashboard'} className={styles.manual}>
          <div  className={styles.link_item_img} style={{
            backgroundImage: `url(${Admin})`
          }}/>
        </a>

        <NavLink to={'/'} className={classNameGetter}>
          <div onClick={logout} className={styles.link_item_img} style={{
            backgroundImage: `url(${Logout})`
          }}/>
        </NavLink>

      </div>
    </div>
  );
};

export default SideBarLayout;
