import { useEffect, useState } from "react";
import { compose } from "redux";
import Dashboard from ".";
import { MonitoringApi } from "../../api/api";
import IDashboard from "../../interfaces/IDashboard";

const DashboardContainer: React.FC = () => {
  const [dashboardInfo, setDashboardInfo] = useState<IDashboard>();

  useEffect(() => {
    MonitoringApi.getDashboard().then((dashboardResponse) => {
      setDashboardInfo(dashboardResponse.dashboardInfo);
    });
  }, []);

  return (
    <Dashboard
      keywordsCount={dashboardInfo?.keywordsCount || 0}
      keywordsGroupsCount={dashboardInfo?.keywordsGroupsCount || 0}
      keywordsPostsCount={dashboardInfo?.keywordsPostsCount || 0}
      keywordsCommentsCount={dashboardInfo?.keywordsCommentsCount || 0}
      keywordsUsersCount={dashboardInfo?.keywordsUsersCount || 0}
      usersCount={dashboardInfo?.usersCount || 0}
    />
  );
};

export default compose<any>(DashboardContainer);
